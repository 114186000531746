export enum PlainTextSize {
	Tiny = "tiny",
	SmallPrint = "smallPrint",
	Sm = "sm",
	Breadcrumb = "breadcrumb",
	Default = "default",
	Large = "large",
	XL = "xl",
}

export interface PlainTextProps {
	size?: PlainTextSize;
	bold?: boolean;
	children: string | React.ReactNode;
}
