import React from "react";
import { type IconProps } from "./icon.types";
import icons from "./icons.json";

export function Icon({ name, size, colour, accessibleName }: IconProps) {
	const svgPath = icons.find((icon) => icon.name === name);

	if (!svgPath) {
		console.warn(`Icon '${name}' not found.`);
		return null;
	}

	const style = {
		width: `${size}px`,
		height: size,
		fill: colour,
	};

	return (
		<>
			{accessibleName ? (
				// When the icon is used as a standalone image, with no text, trigger or action associated with it:
				<svg
					role="img"
					aria-describedby={`${svgPath.name}-icon`}
					style={style}
					viewBox="0 0 24 24"
					fill="bg-blue-secondary"
				>
					<title id={`${svgPath.name}-icon`}>{accessibleName}</title>
					<path d={svgPath.path} />
				</svg>
			) : (
				// When the icon is decorative or used within a link/button with an accessible name (which could be link/button text or aria-label)
				<svg
					aria-hidden
					style={style}
					viewBox="0 0 24 24"
					fill="bg-blue-secondary"
					data-testid={`svg-icon-${svgPath.name}`}
				>
					<path d={svgPath.path} />
				</svg>
			)}
		</>
	);
}
