export enum HeadingLevel {
	H1 = "h1",
	H2 = "h2",
	H3 = "h3",
	H4 = "h4",
}

export interface HeadingProps {
	level: HeadingLevel;
	children: string | React.ReactNode;
}
