"use client";
import { useEffect } from "react";

export const useKeyPress = (targetKey: string, onClick: () => void): void => {
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.key === targetKey) {
				onClick();
			}
		};

		document.addEventListener("keydown", handleKeyDown);

		// Cleanup on unmount
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [targetKey, onClick]);
};
