import { type InputHTMLAttributes } from "react";

export enum FieldType {
	Text = "text",
}

export type TextInputProps = {
	label: string;
	fieldType: FieldType;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;
