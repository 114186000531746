import { type ReactElement } from "react";
import type React from "react";
import { type ImageProps } from "../../atoms/image/image.types";
import { type LinkProps } from "../../atoms/link/link.types";

export interface MenuLinkTwoLevel {
	destination: string;
	title: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- check object is a MenuLinkTwoLevel
export const instanceOfMenuLink = (object: any): object is MenuLinkTwoLevel => {
	return "destination" in object && "title" in object;
};

export interface HeaderProps {
	toggleMenuOpen?: boolean;
	toggleSearchOpen?: boolean;
	homeLinkUrl: string;
	hostedByLinkUrl?: string;
	ctaLink?: ReactElement<LinkProps>;
	logo: ReactElement<ImageProps>;
	hostedByLogo?: ReactElement<ImageProps>;
	buttonRef?: React.RefObject<HTMLElement>;
	onSearch?: boolean;
	menuLinksOneLevel?: ReactElement<LinkProps> | ReactElement<LinkProps>[];
	menuLinksTwoLevel?: Record<
		string,
		{
			header: { title: string };
			links: MenuLinkTwoLevel[] | JSX.Element[];
		}
	>;
	mobileNavMenuLabel: string;
	mobileNavCloseLabel: string;
	mobileNavBackLabel: string;
	mobileNavSearchLabel: string;
}
