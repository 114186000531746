import classNames from "classnames";
import { type LinkProps, LinkType } from "./link.types";

export const LinkClasses = ({ linkType, iconColour }: LinkProps): string => {
	switch (linkType!) {
		case LinkType.ButtonLinkLightBg:
			return classNames(
				"flex group items-center w-fit px-6 py-2.5 text-sm no-underline font-bold tracking-wide border-2 bg-red-primary border-red-primary  text-white hover:bg-white hover:border-2 hover:text-red-primary hover:border-red-primary focus:bg-white focus:border-2 focus:text-red-primary focus:border-red-primary"
			);
		case LinkType.ButtonLinkDarkBg:
			return classNames(
				"flex group items-center w-fit px-6 py-2.5 text-sm no-underline font-bold tracking-wide border-2 bg-white border-white text-red-primary hover:text-white hover:bg-red-primary hover:border-2 hover:border-white focus:text-white focus:bg-red-primary focus:border-2 focus:border-white"
			);
		case LinkType.HeaderCta:
			return classNames(
				"flex items-center justify-center text-center w-full h-full min-w-[60px] p-2.5 text-xs uppercase no-underline hover:underline focus:underline tracking-wide bg-red-primary text-white font-bold md:text-sm md:h-20"
			);

		case LinkType.Breadcrumb:
			return classNames(
				{ "pt-2": iconColour },
				"flex",
				"relative",
				"items-center",
				"w-fit",
				"normal-case",
				"text-xs",
				"antialiased",
				"no-underline",
				"decoration-2",
				"text-grey-40",
				"hover:underline",
				"focus:underline",
				"hover:decoration-1"
			);
		case LinkType.PrimaryFooter:
			return classNames(
				"flex relative items-center w-fit normal-case font-bold text-xs antialiased no-underline decoration-1 underline-offset-4 text-white hover:underline focus:underline"
			);
		case LinkType.SecondaryFooter:
			return classNames(
				"flex relative items-center w-fit normal-case text-xs antialiased no-underline decoration-1 underline-offset-4 text-white hover:underline focus:underline"
			);
		case LinkType.MenuHeader:
			return classNames(
				"no-underline font-bold w-full text-lg py-3.5 md:py-0 md:text-left md:w-auto md:h-full md:flex md:items-center"
			);
		case LinkType.MenuItem:
			return classNames(
				"block items-center normal-case no-underline text-black-primary text-sm antialiased py-2.5 px-4 hover:font-bold focus:font-bold md:py-1.5 md:inline-block md:px-0 md:text-base md:hover:underline"
			);
		case LinkType.DefaultDarkBg:
			return classNames(
				"text-sm md:text-base font-bold underline-offset-5 underline text-white hover:decoration-3"
			);
		case LinkType.Default:
		default:
			return classNames(
				"text-sm md:text-base font-bold underline-offset-5 underline text-blue-link hover:text-blue-linkHover hover:decoration-3"
			);
	}
};
