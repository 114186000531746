"use client";
import React, { useEffect } from "react";
import { PlainText } from "../plain-text/plain-text";
import { PlainTextSize } from "../plain-text/plain-text.types";
import { type ImageProps } from "./image.types";

export function Image({
	src,
	alt,
	caption,
	credit,
	width,
	height,
	specifiedSize,
	loading,
	context,
}: ImageProps) {
	useEffect(() => {
		// Check if an Image component is the first item after a Hero in the content area and change the loading attribute to "eager" to improve performance
		function sibling() {
			const imageAtoms = document.getElementsByClassName("brc-image-atom");

			// Iterate through each image atom
			for (const imageAtom of imageAtoms) {
				const imageAtomParent = imageAtom.parentElement;
				const isHeroSibling = imageAtomParent?.previousElementSibling;

				// Check if the image atom is a sibling of a hero
				if (isHeroSibling?.classList.contains("brc-hero")) {
					if (imageAtom) {
						const imageElement = imageAtom.getElementsByTagName("img")[0];
						if (imageElement) {
							imageElement.setAttribute("loading", "eager");
						}
					}
					// Break out of the loop once the first image after the hero is found
					break;
				}
			}
		}
		sibling();
	}, []);

	const containerClasses =
		context === "standalone"
			? "w-full max-content mx-auto px-4 mb-8 md:mb-12 lg:mb-16 lg:px-0 prose-img:aspect-video prose-img:object-cover"
			: "";

	if (caption || credit) {
		const figCaption =
			caption +
			(caption && credit ? " " : "") +
			(credit ? `(Credit: ${credit})` : "");
		return (
			<div className={`brc-image-atom ${containerClasses}`}>
				<figure className="w-full">
					<img
						src={src}
						alt={alt}
						className={specifiedSize ? "" : "w-full h-auto"}
						width={width}
						height={height}
						loading={loading || "lazy"}
					/>
					<figcaption>
						<PlainText size={PlainTextSize.SmallPrint}>{figCaption}</PlainText>
					</figcaption>
				</figure>
			</div>
		);
	}

	return (
		<img
			src={src}
			alt={alt}
			className={`brc-image-atom ${containerClasses} ${specifiedSize ? "" : "w-full h-auto"} `}
			width={width}
			height={height}
			loading={loading || "lazy"}
		/>
	);
}
