"use client";
import React from "react";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { Button } from "../../atoms/button/button";
import { ButtonStyle } from "../../atoms/button/button.types";
import { type SearchProps } from "./search.types";

export function Search({
	inputLabel,
	buttonText,
	searchPrompt,
}: SearchProps): React.JSX.Element {
	const { handleSubmit, register } = useForm<{ searchTerm: string }>({
		defaultValues: { searchTerm: "" },
		mode: "onSubmit",
		reValidateMode: "onBlur",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false,
		shouldUseNativeValidation: false,
	});

	const onSubmit = ({ searchTerm }: { searchTerm: string }): void => {
		// Sanitize the search term
		const sanitizedSearchTerm = DOMPurify.sanitize(
			searchTerm.trim().replace(/ /g, "+")
		);

		// Redirect to redcross.org.uk search results page
		window.location.href = `https://www.redcross.org.uk/search-results?q=${sanitizedSearchTerm}`;
	};

	return (
		<form className="w-full p-0 bg-grey-10" onSubmit={handleSubmit(onSubmit)}>
			<div className="grid gap-0 grid-cols-12 mx-auto my-0 max-content h-16 md:gap-4">
				<div className="h-full pl-3 col-span-9 md:col-span-10 md:pl-4">
					<div className="flex h-full items-center">
						<Icon name="search" size={26} colour={IconColour.PrimaryBlack} />
						<label htmlFor="searchTerm" className="hidden">
							{inputLabel ?? "Search term"}
						</label>
						<input
							{...register("searchTerm")}
							type="text"
							name="searchTerm"
							id="searchTerm"
							className="focus:outline-none w-full h-full py-5 pl-2 bg-transparent md:pl-4"
							placeholder={searchPrompt ?? "Search"}
							aria-label={inputLabel ?? "Search term"}
						/>
					</div>
				</div>
				<div className="flex h-full col-span-3 md:col-span-2 justify-end">
					<Button buttonStyle={ButtonStyle.Primary} buttonType="submit">
						{buttonText ? buttonText : "Search"}
					</Button>
				</div>
			</div>
		</form>
	);
}
