import classNames from "classnames";
import { PlainTextSize, type PlainTextProps } from "./plain-text.types";

export const PlainTextClasses = ({ size, bold }: PlainTextProps): string => {
	switch (size!) {
		case PlainTextSize.XL:
			return classNames("text-4xl", "md:text-6xl", {
				"font-bold": bold,
			});
		case PlainTextSize.Large:
			return classNames("text-base", "md:text-xl", {
				"font-bold": bold,
			});
		case PlainTextSize.Sm:
			return classNames("text-sm", {
				"font-bold": bold,
			});
		case PlainTextSize.SmallPrint:
			return classNames("text-xs", {
				"font-bold": bold,
			});
		case PlainTextSize.Breadcrumb:
			return classNames(
				"text-xs",
				"text-grey-40",
				"font-normal",
				"antialiased",
				"normal-case"
			);
		case PlainTextSize.Tiny:
			return classNames("text-xxs", "normal-case", "tracking-wide");
		case PlainTextSize.Default:
		default:
			return classNames("text-sm", "md:text-base", {
				"font-bold": bold,
			});
	}
};
