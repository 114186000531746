import { type ReactElement } from "react";
import { type PlainTextProps } from "../../atoms/plain-text/plain-text.types";
import { type HeadingProps } from "../../atoms/heading/heading.types";
import { type ContactDetailProps } from "../../molecules/contact-detail/contact-detail.types";

export enum BgColour {
	DarkRed = "darkRed",
	Green = "green",
	Grey = "grey",
	DarkBlue = "darkBlue",
	Teal = "teal",
}

export interface ContactBannerProps {
	title: ReactElement<HeadingProps>;
	subtitle?: ReactElement<PlainTextProps>;
	bgColour: BgColour;
	contactDetail: ReactElement<ContactDetailProps>[];
}
