import { type ReactElement } from "react";
import { type PlainTextProps } from "../../atoms/plain-text/plain-text.types";
import { type ImageProps } from "../../atoms/image/image.types";

export enum StandaloneContentBoxPosition {
	Left = "Left",
	Right = "Right",
}

export interface CardBasicProps {
	image?: ReactElement<ImageProps>;
	title: string;
	destination: string;
	text: ReactElement<PlainTextProps>;
	contentBox?: StandaloneContentBoxPosition;
}
