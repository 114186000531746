"use client";
import type React from "react";
import { useEffect } from "react";

export const useClickOutsideHandler = (
	refs: React.RefObject<HTMLElement>[],
	onClick: () => void
): void => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent): void => {
			if (refs.every((ref) => !ref.current?.contains(event.target as Node))) {
				onClick();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup on unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [refs, onClick]);
};
