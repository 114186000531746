export enum ButtonStyle {
	Primary = "primary",
	PrimaryDarkBackground = "primary-dark-background",
	Secondary = "secondary",
	SecondaryDarkBackground = "secondary-dark-background",
}

export interface ButtonProps {
	buttonStyle: ButtonStyle;
	buttonType: "button" | "submit" | "reset";
	children: React.ReactNode;
}
