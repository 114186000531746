export enum ContentType {
	Email = "email",
	Phone = "phone",
	Address = "address",
	Link = "link",
}

export interface ContactDetailProps {
	label: string;
	contentType: ContentType;
	content: string;
	supportingText?: string;
}
