export enum IconColour {
	PrimaryRed = "#D0021B",
	PrimaryGreen = "#40A22A",
	PrimaryBlack = "#262626",
	PrimaryTeal = "#2B7586",
	SecondaryRed = "#AD1220",
	SecondaryGreen = "#05853A",
	SecondaryGrey = "#5C7478",
	SecondaryBlue = "#193351",
	TintRed = "#CC434F",
	TintGreen = "#44A46C",
	TintGrey = "#9CAAAE",
	TintBlue = "#475C74",
	TintTeal = "#6A9EA6",
	NeutralWhite = "#FFFFFF",
	NeutralGrey10 = "#F6F6F6",
	NeutralGrey20 = "#E0E0E0",
	NeutralGrey30 = "#BBBBBB",
	NeutralGrey40 = "#717171",
	SystemMustard = "#F1B13B",
	SystemTeal = "#6A9EAA",
}

export interface IconProps {
	name: string;
	size?: number;
	colour?: IconColour;
	accessibleName?: string;
}
