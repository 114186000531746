import { type ReactElement } from "react";
import { type LinkProps } from "../../atoms/link/link.types";
import { type ImageProps } from "../../atoms/image/image.types";

export enum ContentBoxPosition {
	Left = "justify-start",
	Right = "justify-end",
}

export interface HeroHomeProps {
	title: string;
	text: string;
	link: ReactElement<LinkProps>;
	imageSm?: ReactElement<ImageProps>;
	imageMd?: ReactElement<ImageProps>;
	imageLg: ReactElement<ImageProps>;
	contentBox?: ContentBoxPosition;
}
