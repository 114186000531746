import { type IconColour } from "../icon/icon.types";

export enum LinkType {
	DefaultDarkBg = "defaultDarkBg",
	Default = "default",
	ButtonLinkLightBg = "buttonLinkLightBg",
	ButtonLinkDarkBg = "buttonLinkDarkBg",
	Breadcrumb = "breadcrumb",
	HeaderCta = "headerCta",
	PrimaryFooter = "primaryFooter",
	SecondaryFooter = "secondaryFooter",
	MenuHeader = "menuHeader",
	MenuItem = "menuItem",
}

export type LinkTarget = "_self" | "_blank" | "_parent" | "_top";

export interface LinkProps {
	linkType?: LinkType;
	destination: string;
	iconColour?: IconColour;
	selected?: boolean;
	children: React.ReactNode;
	download?: boolean;
	target?: LinkTarget;
	context?: "standalone" | "multicolumn" | "card" | "homehero";
}
