"use client";
import React, { useEffect, useRef, useState } from "react";
import { type LinkProps } from "./link.types";
import { LinkClasses } from "./link.style";

export function Link({
	linkType,
	destination,
	selected,
	target,
	children,
	context,
	...props
}: LinkProps): JSX.Element {
	const [isMainParent, setIsMainParent] = useState(false);
	const linkRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (linkRef.current?.parentElement?.tagName.toLowerCase() === "main") {
			setIsMainParent(true);
		}
	}, []);

	const classes = LinkClasses({ linkType, destination, children });

	const linkElement = (
		<a
			ref={linkRef}
			href={destination}
			className={classes}
			target={target}
			{...(selected ? { "aria-current": "page" } : {})}
			{...props}
		>
			{children}
		</a>
	);

	return isMainParent ? (
		<div className="w-full max-content mx-auto brc-link-container flex mb-4">
			{linkElement}
		</div>
	) : (
		linkElement
	);
}
