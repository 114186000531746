import React from "react";
import { PlainTextClasses } from "./plain-text.style";
import { type PlainTextProps } from "./plain-text.types";

export function PlainText({ size, bold, children, ...props }: PlainTextProps) {
	const classes = PlainTextClasses({ size, bold, children });
	return (
		<p className={classes} {...props}>
			{children}
		</p>
	);
}
