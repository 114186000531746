"use client";
import React, { useRef, useState } from "react";
import { Link } from "../../atoms/link/link";
import { LinkType } from "../../../types";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { Search } from "../search/search";
import { useClickOutsideHandler } from "../../../hooks/use-click-outside-handler";
import { useScrollManager } from "../../../hooks/use-scroll-manager";
import { useKeyPress } from "../../../hooks/use-key-press";
import { type HeaderProps, instanceOfMenuLink } from "./header.types";

export function Header({
	toggleMenuOpen,
	toggleSearchOpen,
	homeLinkUrl,
	ctaLink,
	hostedByLinkUrl,
	logo,
	hostedByLogo,
	buttonRef,
	onSearch,
	menuLinksOneLevel,
	menuLinksTwoLevel = {},
	mobileNavMenuLabel,
	mobileNavCloseLabel,
	mobileNavBackLabel,
	mobileNavSearchLabel,
}: HeaderProps) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const [subMenu, setSubMenu] = useState<string>();

	const subMenuRef = useRef<HTMLElement>(null);
	const closeButtonRef = useRef<HTMLElement>(null);

	const [blockScroll, allowScroll] = useScrollManager();

	useClickOutsideHandler([closeButtonRef], () => {
		if (allowScroll) allowScroll();
	});

	useClickOutsideHandler([subMenuRef, closeButtonRef], () => {
		setSubMenu(undefined);
		setMobileMenuOpen(false);
		if (allowScroll) allowScroll();
	});

	useKeyPress("Escape", () => {
		setMobileMenuOpen(false);
		if (allowScroll) allowScroll();

		if (subMenu) {
			setSubMenu(undefined);
		} else {
			setMobileMenuOpen(false);
			if (allowScroll) allowScroll();
		}

		if (searchOpen) {
			setSearchOpen(false);
		}
	});

	// To display the mobile toggle to display/hide the menu
	if (
		(menuLinksOneLevel && Object.keys(menuLinksOneLevel).length > 0) ||
		(menuLinksTwoLevel && Object.keys(menuLinksTwoLevel).length > 0) ||
		(onSearch && !searchOpen)
	) {
		toggleMenuOpen = true;
	} else {
		toggleMenuOpen = false;
	}

	// If seach provided display the Search button on Nav
	if (onSearch === true) {
		toggleSearchOpen = true;
	} else {
		toggleSearchOpen = false;
	}

	// Mobile search without nav
	const noNavSearchMobile = () => {
		return (
			<div
				className="h-screen overflow-y-scroll"
				ref={subMenuRef as React.RefObject<HTMLDivElement>}
			>
				{onSearch ? (
					<>
						<div className="m-4">
							<Search />
						</div>
						<div className="border-b border-grey-20" />
					</>
				) : null}
			</div>
		);
	};

	const navOneLevelMobile = () => {
		return (
			<div
				className="h-screen overflow-y-scroll"
				ref={subMenuRef as React.RefObject<HTMLDivElement>}
			>
				{onSearch ? (
					<>
						<div className="m-4">
							<Search />
						</div>
						<div className="border-b border-grey-20" />
					</>
				) : null}
				<nav
					lang="en"
					dir="ltr"
					className="contents w-full border-b-2 border-grey-20 absolute bg-white z-10 overflow-y-scroll md:hidden"
					aria-label="Main Navigation"
				>
					<ul className="max-content flex flex-col mx-4">
						{React.Children.map(menuLinksOneLevel, (child) => (
							<li className="flex flex-col group border-t-2 border-grey-20 last:border-b first:border-t-0 last:mb-2">
								<span className="top-2 w-full ml-auto flex items-center ">
									{child}
								</span>
							</li>
						))}
					</ul>
				</nav>
			</div>
		);
	};

	const navTwoLevelMobile = () => {
		if (!subMenu) {
			return (
				<div
					className="h-screen overflow-y-scroll"
					ref={subMenuRef as React.RefObject<HTMLDivElement>}
				>
					{onSearch ? (
						<>
							<div className="m-4">
								<Search />
							</div>
							<div className="border-b border-grey-20" />
						</>
					) : null}
					<nav
						lang="en"
						dir="ltr"
						className="contents w-full border-b-2 border-grey-20 absolute bg-white z-10 overflow-y-scroll md:hidden"
						aria-label="Main Navigation"
					>
						<ul className="max-content flex flex-col mx-4">
							{Object.keys(menuLinksTwoLevel).map((key) => {
								return (
									<li
										key={key}
										className="py-3.5 flex flex-col prose-a:h-full group border-t-2 border-grey-20 last:border-b first:border-t-0 last:mb-2 md:first:border-t-4 md:inline-block md:border-y-4 md:border-y-transparent md:hover:border-b-red-primary"
										onClick={() => {
											setSubMenu(key);
										}}
									>
										<button
											className="top-2 w-full ml-auto flex items-center justify-between"
											aria-expanded="false"
											aria-label={`Show ${menuLinksTwoLevel[key]?.header.title} Submenu`}
										>
											<span className="text-lg font-bold text-left">
												{menuLinksTwoLevel[key]?.header.title}
											</span>
											<span className="flex">
												<Icon
													name="chevron_right"
													size={24}
													colour={IconColour.PrimaryBlack}
												/>
											</span>
										</button>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			);
		}
		return (
			<div
				className="mx-4 h-screen overflow-y-scroll"
				ref={subMenuRef as React.RefObject<HTMLDivElement>}
			>
				<button
					onClick={() => {
						setSubMenu(undefined);
					}}
					className="py-4 w-full flex items-center justify-start align-middle"
					aria-label="Go Back To Main Navigation"
				>
					<Icon
						name="chevron_left"
						size={24}
						colour={IconColour.PrimaryBlack}
					/>
					{mobileNavBackLabel}
				</button>
				<div className="border-b border-grey-20" />
				<div className="text-lg font-bold py-3.5">{subMenu}</div>
				<ul className="h-[calc(100vh+100px)] md:h-auto">
					{menuLinksTwoLevel[subMenu]?.links.map((link, idx) => {
						return instanceOfMenuLink(link) ? (
							<li
								key={idx}
								className="text-sm text-grey-40 border-t border-grey-20 last:border-b first:border-t-0 hover:bg-grey-20 md:border-0 md:border-r-2 md:border-r-grey-20 md:hover:bg-white md:w-4/12 md:pl-12"
							>
								<Link
									linkType={LinkType.MenuItem}
									destination={link.destination}
								>
									{link.title}
								</Link>
							</li>
						) : (
							link
						);
					})}
				</ul>
			</div>
		);
	};

	const navOneLevelDesktop = () => {
		return (
			<nav
				lang="en"
				dir="ltr"
				className="hidden w-full border-b border-grey-20 bg-white px-4 z-50 md:block lg:mx-auto lg:px-0"
				aria-label="Main Navigation"
			>
				<ul className="relative max-content mx-auto block justify-start h-full md:flex md:h-16">
					{React.Children.map(menuLinksOneLevel, (child) => (
						<li className="py-4 mr-12 last:mr-0 prose-a:h-full group block items-center justify-center hover:pt-5 hover:border-b-red-primary hover:border-b-4 lg:mr-12">
							{child}
						</li>
					))}
				</ul>
			</nav>
		);
	};

	const navTwoLevelDesktop = () => {
		return (
			<nav
				lang="en"
				dir="ltr"
				className="hidden w-full border-b border-grey-20 bg-white px-4 z-50 md:block lg:mx-auto lg:px-0"
				aria-label="Main Navigation"
			>
				<ul
					ref={subMenuRef as React.RefObject<HTMLUListElement>}
					className="relative max-content mx-auto block justify-start h-full md:flex md:h-16"
				>
					{Object.keys(menuLinksTwoLevel).map((key) => {
						return (
							<li
								key={key}
								className="py-4 mr-12 last:mr-0 prose-a:h-full group block items-center justify-center hover:pt-5 hover:border-b-red-primary hover:border-b-4 lg:mr-12"
								onClick={() => {
									if (subMenu === key) {
										setSubMenu(undefined);
										if (allowScroll) allowScroll();
									} else {
										setSubMenu(key);
										if (blockScroll) blockScroll();
									}
								}}
							>
								<button
									className="text-left w-auto h-full flex items-center justify-center cursor-pointer"
									aria-expanded={subMenu === key && true}
									aria-label={
										subMenu === key
											? `Hide ${menuLinksTwoLevel[key]?.header.title} Submenu`
											: `Show ${menuLinksTwoLevel[key]?.header.title} Submenu`
									}
								>
									<span className="font-bold text-lg">
										{menuLinksTwoLevel[key]?.header.title}
									</span>
									<Icon
										name="expand_more"
										size={24}
										colour={IconColour.PrimaryBlack}
									/>
								</button>
								{subMenu === key && (
									<div className="py-0 px-0 mt-px w-full z-10 absolute top-full left-0 right-0 mx-auto max-content bg-white">
										<ul
											className={`grid h-full grid-flow-col grid-cols-3 md:py-8 ${(menuLinksTwoLevel[key]?.links.length ?? 0) > 12 ? "grid-rows-[repeat(5,_auto)]" : "grid-rows-[repeat(4,_auto)]"} `}
										>
											{menuLinksTwoLevel[key]?.links.map((link, idx) => {
												const columnIndex = idx % 3;
												return instanceOfMenuLink(link) ? (
													<li
														key={idx}
														className={`text-base md:border-0 md:border-r-2 md:border-r-grey-20 ${columnIndex === 2 ? "md:border-0" : ""} md:hover:bg-white md:pl-5 lg:pl-8 hover:bg-grey-20`}
													>
														<Link
															linkType={LinkType.MenuItem}
															destination={link.destination}
														>
															{link.title}
														</Link>
													</li>
												) : (
													link
												);
											})}
										</ul>
									</div>
								)}
							</li>
						);
					})}
				</ul>
			</nav>
		);
	};

	// To define which menu to use for small screens mobile base on the links provided - Also to display the search if provided when there isn't a nav
	let mobileNav: JSX.Element | null = null;
	if (menuLinksOneLevel && Object.keys(menuLinksOneLevel).length > 0) {
		mobileNav = navOneLevelMobile();
	} else if (menuLinksTwoLevel && Object.keys(menuLinksTwoLevel).length > 0) {
		mobileNav = navTwoLevelMobile();
	} else if (
		(!menuLinksOneLevel || React.Children.count(menuLinksOneLevel) === 0) &&
		(!menuLinksTwoLevel || Object.keys(menuLinksTwoLevel).length === 0) &&
		onSearch
	) {
		mobileNav = noNavSearchMobile();
	}

	// To define which menu to use for large screens tablet/desktop base on the links provided
	let desktopNav: JSX.Element | null = null;
	if (menuLinksOneLevel && Object.keys(menuLinksOneLevel).length > 0) {
		desktopNav = navOneLevelDesktop();
	} else if (menuLinksTwoLevel && Object.keys(menuLinksTwoLevel).length > 0) {
		desktopNav = navTwoLevelDesktop();
	}

	return (
		<div>
			<div className="border-b border-grey-20 h-16 w-full z-50 bg-white md:h-20">
				<div className="max-content mx-auto flex items-center h-16 w-full md:h-20">
					<div className="flex px-4 py-3 w-full md:py-4 lg:px-0 prose-img:w-full prose-img:h-auto prose-a:block">
						<div className="flex ">
							{hostedByLogo && hostedByLinkUrl ? (
								<>
									<div className="flex items-center basis-1/2 max-w-[190px] max-h-[48px] prose-img:aspect-[95/24]">
										<Link destination={homeLinkUrl}>{logo}</Link>
									</div>
									<div className="flex items-center basis-1/2 max-w-[190px] max-h-[48px] prose-img:aspect-[95/24]">
										<Link destination={hostedByLinkUrl}>{hostedByLogo}</Link>
									</div>
								</>
							) : (
								<div className="flex items-center max-w-[232px] max-h-[48px] prose-img:aspect-[29/6]">
									<Link destination={homeLinkUrl}>{logo}</Link>
								</div>
							)}
						</div>
					</div>
					{toggleMenuOpen ? (
						mobileMenuOpen ? (
							<button
								onClick={() => {
									setMobileMenuOpen(false);
								}}
								className="flex flex-col cursor-pointer justify-center items-center w-full max-w-[64px] h-full float-right text-xxs hover:bg-grey-20 border-b border-b-white border-l border-l-grey-20 md:hidden"
								ref={buttonRef as React.RefObject<HTMLButtonElement>}
								aria-expanded="true"
								aria-label="Hide Navigation Menu"
							>
								<Icon name="close" size={24} colour={IconColour.PrimaryBlack} />
								<span className="block w-full" aria-hidden="true">
									{mobileNavCloseLabel}
								</span>
							</button>
						) : (
							<button
								onClick={() => {
									setMobileMenuOpen(true);
								}}
								className="flex flex-col cursor-pointer justify-center items-center w-full max-w-[64px] h-full float-right text-xxs hover:bg-grey-20 border-l border-r border-grey-20 md:hidden"
								aria-expanded="false"
								aria-label="Show Navigation Menu"
							>
								<Icon
									name={
										(!menuLinksOneLevel ||
											React.Children.count(menuLinksOneLevel) === 0) &&
										(!menuLinksTwoLevel ||
											Object.keys(menuLinksTwoLevel).length === 0) &&
										onSearch
											? "search"
											: "menu"
									}
									size={24}
									colour={IconColour.PrimaryBlack}
								/>
								<span className="block w-full" aria-hidden="true">
									{(!menuLinksOneLevel ||
										React.Children.count(menuLinksOneLevel) === 0) &&
									(!menuLinksTwoLevel ||
										Object.keys(menuLinksTwoLevel).length === 0) &&
									onSearch
										? mobileNavSearchLabel
										: mobileNavMenuLabel}
								</span>
							</button>
						)
					) : null}

					{toggleSearchOpen ? (
						searchOpen ? (
							<button
								aria-haspopup="true"
								onClick={() => {
									setSearchOpen(false);
								}}
								className="hidden cursor-pointer text-center justify-center items-center max-w-[92px] float-right w-full h-full text-xxs bg-grey-10 border-l border-r border-grey-20 md:flex md:flex-col"
								aria-expanded="true"
								aria-label="Hide Search Section"
							>
								<Icon name="close" size={24} colour={IconColour.PrimaryBlack} />
								<span className="block w-full" aria-hidden="true">
									{mobileNavCloseLabel}
								</span>
							</button>
						) : (
							<button
								aria-haspopup="true"
								onClick={() => {
									setSearchOpen(true);
								}}
								className="hidden cursor-pointer text-center justify-center items-center max-w-[92px] float-right w-full h-full text-xxs hover:bg-grey-20 border-l border-r border-grey-20 md:flex md:flex-col"
								aria-expanded="false"
								aria-label="Show Search Section"
							>
								<Icon
									name="search"
									size={24}
									colour={IconColour.PrimaryBlack}
								/>
								<span className="block w-full" aria-hidden="true">
									{mobileNavSearchLabel}
								</span>
							</button>
						)
					) : null}

					{ctaLink ? (
						<div
							className={`mr-2 ${mobileMenuOpen || searchOpen ? "hidden" : ""} bg-red-primary w-full h-full max-w-[80px] float-right md:max-w-[160px]`}
						>
							{ctaLink}
						</div>
					) : null}
				</div>
			</div>

			{mobileMenuOpen ? (
				// Mobile Nav
				<>{mobileNav}</>
			) : (
				// Tablet/Desktop Nav
				<>
					{searchOpen ? (
						<div className="hidden w-full border-b border-grey-20 bg-white md:flex px-0">
							<Search />
						</div>
					) : (
						<>{desktopNav}</>
					)}
					{subMenu ? (
						<div className="h-full w-full bg-grey-30 bg-opacity-70 absolute z-[1]" />
					) : null}
				</>
			)}
		</div>
	);
}
