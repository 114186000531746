import React from "react";
import { type ButtonProps, ButtonStyle } from "./button.types";

export function Button({ buttonType, children, buttonStyle }: ButtonProps) {
	switch (buttonStyle) {
		case ButtonStyle.Primary:
			return (
				<button
					type={buttonType}
					className="w-full min-w-[160px] min-h-12 p-2 px-2.5 text-base no-underline font-bold border-2 bg-red-primary border-red-primary text-white hover:bg-white hover:border-2 hover:text-red-primary hover:border-red-primary md:w-auto"
				>
					{children}
				</button>
			);
		case ButtonStyle.PrimaryDarkBackground:
			return (
				<button
					type={buttonType}
					className="w-full min-w-[160px] min-h-12 p-2 px-2.5 text-base no-underline font-bold border-2 bg-white border-white text-red-primary hover:bg-transparent hover:border-2 hover:text-white hover:border-white md:w-auto"
				>
					{children}
				</button>
			);
		case ButtonStyle.Secondary:
			return (
				<button
					type={buttonType}
					className="w-full min-w-[160px] min-h-12 p-2 px-2.5 text-base no-underline font-bold border-2 bg-white border-red-primary text-red-primary hover:bg-red-primary hover:border-2 hover:text-white hover:border-red-primary md:w-auto"
				>
					{children}
				</button>
			);
		case ButtonStyle.SecondaryDarkBackground:
			return (
				<button
					type={buttonType}
					className="w-full min-w-[160px] min-h-12 p-2 px-2.5 text-base no-underline font-bold border-2 bg-transparent border-white text-white hover:bg-white hover:border-2 hover:text-red-primary hover:border-white md:w-auto"
				>
					{children}
				</button>
			);
	}
}
